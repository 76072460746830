import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import partnerSlice from './partner-slice'
import heatmapSlice from './heatmap-slice'

const reducer = combineReducers({
    partners: partnerSlice,
    heatmap: heatmapSlice
})
const store = configureStore({
    reducer
})

export default store