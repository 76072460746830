import "maplibre-gl/dist/maplibre-gl.css";
import "@maplibre/maplibre-gl-geocoder/dist/maplibre-gl-geocoder.css";
import "../components/geocoder.css";
import "../components/marker.css";
import "@watergis/mapbox-gl-valhalla/css/styles.css";
import {
  Box,
  Card,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  Slide,
  Slider,
  Typography,
} from "@mui/material";
import maplibregl from "maplibre-gl";
import React, { useEffect, useState } from "react";
import Map, {
  Layer,
  Marker,
  NavigationControl,
  Popup,
  Source,
} from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";
import { setOpacity } from "../heatmap-slice";
import { useLocation } from "react-router-dom";
import MaplibreGeocoder from "@maplibre/maplibre-gl-geocoder";

const TalentHeatmap = () => {
  const mapRef = React.useRef();
  const [heatmapRange, setHeatmapRange] = useState([25, 100]);
  const [allData, setAllData] = useState(null);
  const [searching, setSearching] = useState(false);
  const heatmapOpacity = useSelector((state) => state.heatmap.opacity);
  const dispatch = useDispatch();

  var geocoder_api = {
    forwardGeocode: async (config) => {
      const features = [];

      try {
        setSearching(true);
        let request =
          "https://api-adresse.data.gouv.fr/search/?q=" + config.query;
        /*'https://nominatim.openstreetmap.org/search?q=' +
                config.query +
                '&format=geojson&polygon_geojson=1&addressdetails=1';*/
        const response = await fetch(request);
        const geojson = await response.json();
        for (let feature of geojson.features) {
          /*let center = [
                    feature.bbox[0] +
                        (feature.bbox[2] - feature.bbox[0]) / 2,
                    feature.bbox[1] +
                        (feature.bbox[3] - feature.bbox[1]) / 2
                ];*/
          let center = [
            feature.geometry.coordinates[0],
            feature.geometry.coordinates[1],
          ];
          let point = {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: center,
            },
            //place_name: feature.properties.display_name,
            place_name: feature.properties.label,
            properties: feature.properties,
            //text: feature.properties.display_name,
            text: feature.properties.label,
            place_type: ["place"],
            center: center,
          };
          features.push(point);
        }
      } catch (e) {
        setSearching(false);
        console.error(`Failed to forwardGeocode with error: ${e}`);
      }

      return {
        features: features,
      };
    },
  };

  const onMapLoad = React.useCallback(() => {
    mapRef.current.resize();
    const markerEl = document.createElement("div");
    const markerWidth = "48px";
    const markerHeight = "48px";
    markerEl.className = "marker marker-client";
    markerEl.style.backgroundImage = `url(/epingle.png)`;
    markerEl.style.filter =
      "hue-rotate(50deg) saturate(10) drop-shadow(rgb(121, 121, 121) 4px 2px 3px)";
    markerEl.style.width = `${markerWidth}px;`;
    markerEl.style.height = `${markerHeight}px;`;

    var geocoder = new MaplibreGeocoder(geocoder_api, {
      maplibregl: maplibregl,
      marker: { element: markerEl, draggable: false },
      showResultsWhileTyping: true,
      countries: "fr", //,
      /*filter: function (item) {
          return item.properties.display_name.includes("France");
      },*/
    });
    mapRef.current.addControl(geocoder);
    geocoder.on("result", function (e) {
      setSearching(false);

      var lon = e.result.geometry.coordinates[0];
      var lat = e.result.geometry.coordinates[1];

      //setClientPosition([lon, lat]);
    });
  }, []);

  const handleHeatmapRangeChange = (event, newValue) => {
    if (event.type === "mousedown") {
      return;
    }
    setHeatmapRange(newValue);
  };

  const handleHeatmapOpacityChange = (event, newValue) => {
    dispatch(setOpacity(newValue));
  };

  const circleLayer = {
    id: "circleLayer",
    type: "circle",
    source: "1fwr3mj6i",
    paint: {
      "circle-stroke-opacity": heatmapOpacity / 100,
      "circle-color": "#000000",
      "circle-stroke-width": 20,
      "circle-stroke-color": [
        "interpolate",
        ["linear"],
        ["*", 0.03, ["get", "moyenneTh"]],
        0,
        "rgba(0, 0, 255, 0)",
        0.1,
        "royalblue",
        0.3,
        "cyan",
        0.5,
        "lime",
        0.7,
        "yellow",
        1,
        "red",
      ],
      "circle-radius": 0,
    },
  };

  const textLayer = {
    id: "textLayer",
    type: "symbol",
    source: "1fwr3mj6i",
    layout: {
      "text-field": ["get", "moyenneTh"],
      "text-size": 15,
      "text-padding": 0,
      "text-font": ["Open Sans Regular"],
    },
    paint: {
      "text-color": "rgba(10, 9, 9, 1)",
      "text-opacity": heatmapOpacity / 100,
    },
  };

  const [auth, setAuth] = useState(
    new URLSearchParams(useLocation().search).get("auth")
  );
  const authToken = "t4l3ntb4s1cAUTHPROTECTION";

  useEffect(() => {
    document.title = "Foundy";
    if (auth === authToken) {
      /* global fetch */
      fetch("https://aqua2000.fr/wp-tools/durete/durete.json", {
        cache: "no-store",
      })
        .then((resp) => resp.json())
        .then((json) => setAllData(json))
        .catch((err) => console.error("Could not load data", err)); // eslint-disable-line
    }
  }, []);

  return auth === authToken ? (
    <div className="App" style={{ height: "100%" }}>
      <Map
        mapLib={maplibregl}
        ref={mapRef}
        onLoad={onMapLoad}
        initialViewState={{
          longitude: 2.82,
          latitude: 47.346,
          zoom: 5,
        }}
        style={{ width: "100%", position: "fixed", height: "100%" }}
        mapStyle="https://aqua2000.fr/wp-tools/durete/standard.json"
      >
        <Grid item xs={12}>
          <Box style={{ display: "flex", flex: 1, flexDirection: "column" }}>
            <Box sx={{ marginTop: "60px" }}>
              <FormControl variant="filled" sx={{ background: "#fff" }}>
                <InputLabel id="select-partner-search-provider">
                  Chercher
                </InputLabel>
              </FormControl>
            </Box>
            <Box flex={1} ml={1} mr={1} mt={1}>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 220,
                  bgcolor: "background.paper",
                  maxHeight: "calc(90vh - 32px - 64px)",
                  overflow: "hidden",
                  borderRadius: "10px",
                }}
              >
                <ListItem>
                  <Typography variant="overline" mr={1}>
                    Dureté TH
                  </Typography>
                  <Slider
                    getAriaLabel={() => "Dureté TH"}
                    value={heatmapRange}
                    onChange={handleHeatmapRangeChange}
                    valueLabelDisplay="on"
                    disableSwap
                  />
                </ListItem>
              </List>
            </Box>
          </Box>
        </Grid>
        {allData ? (
          <Source
            type="geojson"
            data={{
              type: allData.type,
              name: allData.name,
              crs: allData.crs,
              features: allData.features.filter(
                (elemInside) =>
                  elemInside.properties.moyenneTh > heatmapRange[0] &&
                  elemInside.properties.moyenneTh < heatmapRange[1]
              ),
            }}
          >
            <Layer {...circleLayer} />
            <Layer {...textLayer} />
          </Source>
        ) : null}
      </Map>
    </div>
  ) : null;
};
export default TalentHeatmap;
