import React, { useRef, useState, useEffect } from "react";
import RegisterForm from "../components/registerform";
import "../components/login.css"

const Register = () => {
    useEffect(() => {
        document.title = "Foundy"
    }, []);
    return (
        <div className="App">
            <RegisterForm />
        </div>
    );
};

export default Register;