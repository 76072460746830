export const sosPartners = [
    {
      "email": "kevin.savarit@sos-bricolage.com",
      "city": "\tAZAY sur THOUET (79)",
      "phone": "06 80 28 44 82",
      "locate": {
        "address": "8 Pisseloube",
        "complement": "",
        "postalCode": "79130",
        "city": "AZAY sur THOUET",
        "country": "France",
        "marker": "1",
        "geolocLat": "46.63249",
        "geolocLng": "-0.3559088"
      }
    },
    {
      "email": "eric.gastaud@sos-bricolage.com",
      "city": "\tBOURG MADAME (66)",
      "phone": "06 81 01 01 64",
      "locate": {
        "address": "19 rue de Cerdagne",
        "complement": "",
        "postalCode": "66760",
        "city": "BOURG MADAME",
        "country": "",
        "marker": "1",
        "geolocLat": "42.4315007",
        "geolocLng": "1.9420266"
      }
    },
    {
      "email": "sebastien.borel@sos-bricolage.com",
      "city": "\tFRANCHEVILLE (69)",
      "phone": "06 19 29 08 17",
      "locate": {
        "address": "19 Chemin des Mouilles",
        "complement": "",
        "postalCode": "69340",
        "city": "FRANCHEVILLE",
        "country": "",
        "marker": "1",
        "geolocLat": "45.7278098",
        "geolocLng": "4.7454843"
      }
    },
    {
      "email": "yves.labesse@sos-bricolage.com",
      "city": "\tGUERET  (23)",
      "phone": "05 55 61 94 03",
      "locate": {
        "address": "7 le roudeau",
        "complement": "",
        "postalCode": "23000",
        "city": "Gueret ",
        "country": "",
        "marker": "1",
        "geolocLat": "46.2244585",
        "geolocLng": "1.8815598"
      }
    },
    {
      "email": "pierre.gaudet@sos-bricolage.com",
      "city": "\tMERIGNAC (33)",
      "phone": "06 01 92 96 91",
      "locate": {
        "address": "9 rue des fleurs",
        "complement": "",
        "postalCode": "33700",
        "city": "MERIGNAC",
        "country": "",
        "marker": "1",
        "geolocLat": "44.8467507",
        "geolocLng": "-0.6405257"
      }
    },
    {
      "email": "luc.blazy@sos-bricolage.com",
      "city": "\tPEYRIAC DE MER  (11)",
      "phone": "06 47 63 77 34",
      "locate": {
        "address": "19 rue des Corbières",
        "complement": "",
        "postalCode": "11440",
        "city": "PEYRIAC de Mer",
        "country": "FRANCE",
        "marker": "1",
        "geolocLat": "43.0859471",
        "geolocLng": "2.9559403"
      }
    },
    {
      "email": "emmanuel.andreoni@sos-bricolage.com",
      "city": "\tPFAFFENHEIM (68)",
      "phone": "06 38 46 85 09",
      "locate": {
        "address": "6 bis Rue des Anémones ",
        "complement": "",
        "postalCode": "68250",
        "city": "PFAFFENHEIM",
        "country": "",
        "marker": "1",
        "geolocLat": "47.9889814",
        "geolocLng": "7.291841300000001"
      }
    },
    {
      "email": "julien.bigand@sos-bricolage.com",
      "city": "\tSTEENVOORDE (59)",
      "phone": "06 64 77 46 36",
      "locate": {
        "address": "47 rue Remy Goetgheluck",
        "complement": "",
        "postalCode": "59114",
        "city": "STEENVOORDE",
        "country": "",
        "marker": "1",
        "geolocLat": "50.8109401",
        "geolocLng": "2.5781214"
      }
    },
    {
      "email": "fabien.fagundes@sos-bricolage.com",
      "city": "\tTHIEMBRONNE  (62)",
      "phone": "07 81 51 29 12",
      "locate": {
        "address": "27 Route de Calais",
        "complement": "",
        "postalCode": "62560",
        "city": "Thiembronne",
        "country": "France",
        "marker": "1",
        "geolocLat": "50.6354496",
        "geolocLng": "2.0729157"
      }
    },
    {
      "email": "florent.bouyjou@sos-bricolage.com",
      "city": "\tVILLENEUVE TOLOSANE (31)",
      "phone": "07 85 21 20 02",
      "locate": {
        "address": "5 rue de l'eglantier ",
        "complement": "",
        "postalCode": "31270",
        "city": "VILLENEUVE TOLOSANE",
        "country": "",
        "marker": "1",
        "geolocLat": "43.5300217",
        "geolocLng": "1.3296499"
      }
    },
    {
      "email": "thierry.riedinger@sos-bricolage.com",
      "city": "\tVILLERS SUR COUDUN  (60)",
      "phone": "07 86 73 34 50",
      "locate": {
        "address": "4b Rue d'Offémont ",
        "complement": "",
        "postalCode": "60150",
        "city": "VILLERS SUR COUDUN ",
        "country": "",
        "marker": "1",
        "geolocLat": "49.48697660000001",
        "geolocLng": "2.8084083"
      }
    },
    {
      "email": "bruno.bouquey@sos-bricolage.com",
      "city": "ANDERNOS (33)",
      "phone": "06 65 45 89 77",
      "locate": {
        "address": "17 Boulevard de l'Océan",
        "complement": "",
        "postalCode": "33510",
        "city": "Andernos-les-Bains",
        "country": "France",
        "marker": "1",
        "geolocLat": "44.728404",
        "geolocLng": "-1.0849035"
      }
    },
    {
      "email": "david.cousin@sos-bricolage.com",
      "city": "ANGLET (64)",
      "phone": "06 17 18 68 75",
      "locate": {
        "address": "9 Allée Gabriel Péri",
        "complement": "",
        "postalCode": "64600",
        "city": "Anglet",
        "country": "France",
        "marker": "1",
        "geolocLat": "43.516962",
        "geolocLng": "-1.5053059"
      }
    },
    {
      "email": "yann.outil@sos-bricolage.com",
      "city": "ARCACHON (33)",
      "phone": "06 72 41 37 78",
      "locate": {
        "address": "72 Avenue de la Libération",
        "complement": "",
        "postalCode": "33120",
        "city": "Arcachon",
        "country": "France",
        "marker": "1",
        "geolocLat": "44.6519054",
        "geolocLng": "-1.1549074"
      }
    },
    {
      "email": "mathieu.guenand@sos-bricolage.com",
      "city": "ARGENTON SUR CREUSE (36)",
      "phone": "06 67 96 89 15",
      "locate": {
        "address": "43 Allée des Acacias",
        "complement": "",
        "postalCode": "36200",
        "city": "Argenton-sur-Creuse",
        "country": "France",
        "marker": "1",
        "geolocLat": "46.5876275",
        "geolocLng": "1.529104"
      }
    },
    {
      "email": "jerome.ribo@sos-bricolage.com",
      "city": "AUBAGNE (13)",
      "phone": "06 11 67 84 98",
      "locate": {
        "address": "10 Avenue Jean-Charles Napp",
        "complement": "",
        "postalCode": "13400",
        "city": "AUBAGNE",
        "country": "France",
        "marker": "1",
        "geolocLat": "43.27982840000001",
        "geolocLng": "5.540150199999999"
      }
    },
    {
      "email": "christophe.martin@sos-bricolage.com",
      "city": "AULNAY SUR ITON (27)",
      "phone": "06 26 28 19 61",
      "locate": {
        "address": "10 Allée Romaine",
        "complement": "",
        "postalCode": "27180",
        "city": "Aulnay-sur-Iton",
        "country": "France",
        "marker": "1",
        "geolocLat": "48.9927451",
        "geolocLng": "1.0528573"
      }
    },
    {
      "email": "marc.devisme@sos-bricolage.com",
      "city": "AURIOL (13)",
      "phone": "06 14 24 18 04",
      "locate": {
        "address": "788 Chemin Saint-Francet",
        "complement": "",
        "postalCode": "13390",
        "city": "Auriol",
        "country": "France",
        "marker": "1",
        "geolocLat": "43.3641866",
        "geolocLng": "5.6482766"
      }
    },
    {
      "email": "bruno.gomez@sos-bricolage.com",
      "city": "AUSSILLON  (81)",
      "phone": "06 38 19 51 66",
      "locate": {
        "address": "112 Rue Charles Péguy",
        "complement": "",
        "postalCode": "81200",
        "city": "Aussillon",
        "country": "France",
        "marker": "1",
        "geolocLat": "43.5071854",
        "geolocLng": "2.3706226"
      }
    },
    {
      "email": "david.halleur@sos-bricolage.com",
      "city": "AUXERRE (89)",
      "phone": "06 70 85 62 94",
      "locate": {
        "address": "25 Rue Guynemer",
        "complement": "",
        "postalCode": "89000",
        "city": "Auxerre",
        "country": "France",
        "marker": "1",
        "geolocLat": "47.8093819",
        "geolocLng": "3.5735016"
      }
    },
    {
      "email": "aurelien.louet@sos-bricolage.com",
      "city": "AVESSAC (44)",
      "phone": "06 27 25 24 50",
      "locate": {
        "address": "31 La Fitière",
        "complement": "",
        "postalCode": "44460",
        "city": "Avessac",
        "country": "France",
        "marker": "1",
        "geolocLat": "47.661388",
        "geolocLng": "-1.95648"
      }
    },
    {
      "email": "philippe.jeandel@sos-bricolage.com",
      "city": "Auray-Vannes  (56)",
      "phone": "06 50 38 26 85",
      "locate": {
        "address": "4 Rue Marc’H Gwen",
        "complement": "",
        "postalCode": "56400",
        "city": "Sainte-Anne-d'Auray",
        "country": "France",
        "marker": "1",
        "geolocLat": "47.7015674",
        "geolocLng": "-2.945077800000001"
      }
    },
    {
      "email": "laurent.gondcaille@sos-bricolage.com",
      "city": "BAGARD (30)",
      "phone": "06 42 85 93 89",
      "locate": {
        "address": "294 Chemin de la Draille du Gour",
        "complement": "",
        "postalCode": "30140",
        "city": "Bagard",
        "country": "France",
        "marker": "1",
        "geolocLat": "44.0615132",
        "geolocLng": "4.0276607"
      }
    },
    {
      "email": "william.morault@sos-bricolage.com",
      "city": "BASSE GOULAINE (44)",
      "phone": "07 69 728 023",
      "locate": {
        "address": "188 Rue de Goulaine",
        "complement": "",
        "postalCode": "44115",
        "city": "Basse-Goulaine",
        "country": "France",
        "marker": "1",
        "geolocLat": "47.2046817",
        "geolocLng": "-1.4569807"
      }
    },
    {
      "email": "yannick.lavergne@sos-bricolage.com",
      "city": "BELLEVIGNY (85)",
      "phone": "06 42 77 25 24",
      "locate": {
        "address": "32 Rue de l'Épine",
        "complement": "",
        "postalCode": "85170",
        "city": "Bellevigny",
        "country": "France",
        "marker": "1",
        "geolocLat": "46.8065544",
        "geolocLng": "-1.4304173"
      }
    },
    {
      "email": "alexis.leoty@sos-bricolage.com",
      "city": "BIGNAN (56)",
      "phone": "06 73 58 06 93",
      "locate": {
        "address": "Le Reste",
        "complement": "",
        "postalCode": "56500",
        "city": "Bignan",
        "country": "France",
        "marker": "1",
        "geolocLat": "47.8710888",
        "geolocLng": "-2.762233"
      }
    },
    {
      "email": "marc.pattier@sos-bricolage.com",
      "city": "BLOT L EGLISE (63)",
      "phone": "0787258362",
      "locate": {
        "address": "ROUTE DE SAINT PARDOUX LE BOURG ",
        "complement": "",
        "postalCode": "63440",
        "city": "BLOT L EGLISE",
        "country": "France",
        "marker": "1",
        "geolocLat": "46.03765335928522",
        "geolocLng": "2.9544044926223645"
      }
    },
    {
      "email": "yazid.haouati@sos-bricolage.com",
      "city": "CAVANAC (11)",
      "phone": "07 43 01 73 65",
      "locate": {
        "address": "90 Allée Pierre Mendès France",
        "complement": "",
        "postalCode": "11570",
        "city": "Cavanac",
        "country": "France",
        "marker": "1",
        "geolocLat": "43.167494",
        "geolocLng": "2.321575"
      }
    },
    {
      "email": "henri.mary@sos-bricolage.com",
      "city": "CERONS (33)",
      "phone": "06 11 25 02 99",
      "locate": {
        "address": "5 Lieu Dit Caubillon",
        "complement": "",
        "postalCode": "33720",
        "city": "CERONS",
        "country": "",
        "marker": "1",
        "geolocLat": "44.6336155",
        "geolocLng": "-0.3461658"
      }
    },
    {
      "email": "rija.rakotovao@sos-bricolage.com",
      "city": "CEYRAT (63)",
      "phone": "06 13 76 94 14",
      "locate": {
        "address": "1 Allée de Pré Soubre",
        "complement": "",
        "postalCode": "63122",
        "city": "CEYRAT",
        "country": "",
        "marker": "1",
        "geolocLat": "45.7312174",
        "geolocLng": "3.0633308"
      }
    },
    {
      "email": "laurent.tanneur@sos-bricolage.com",
      "city": "COGNIN (73)",
      "phone": "07 66 84 73 60",
      "locate": {
        "address": "26 rue Maurice RAVEL",
        "complement": "",
        "postalCode": "73160",
        "city": "COGNIN",
        "country": "",
        "marker": "1",
        "geolocLat": "45.5684811",
        "geolocLng": "5.8886481"
      }
    },
    {
      "email": "",
      "city": "CORMONTREUIL (51)",
      "phone": "06 65 17 01 75",
      "locate": {
        "address": "13 Rue Madeleine Brès",
        "complement": "",
        "postalCode": "51350",
        "city": "Cormontreuil",
        "country": "France",
        "marker": "1",
        "geolocLat": "49.2135373",
        "geolocLng": "4.0351564"
      }
    },
    {
      "email": "franck.robles@sos-bricolage.com",
      "city": "DAX (40)",
      "phone": "06 95 12 48 67",
      "locate": {
        "address": "5 Rue des Coquelicots",
        "complement": "",
        "postalCode": "40100",
        "city": "Dax",
        "country": "France",
        "marker": "1",
        "geolocLat": "43.6915821",
        "geolocLng": "-1.0389031"
      }
    },
    {
      "email": "carlos.dacosta@sos-bricolage.com",
      "city": "DECINES CHARPIEU (69)",
      "phone": "06 59 02 20 27",
      "locate": {
        "address": "11 Avenue Edouard Herriot",
        "complement": "",
        "postalCode": "69150",
        "city": "Décines-Charpieu",
        "country": "France",
        "marker": "1",
        "geolocLat": "45.77152",
        "geolocLng": "4.9606467"
      }
    },
    {
      "email": "tanguy.delignoux@sos-bricolage.com",
      "city": "DOMALAIN (35)",
      "phone": "06 59 71 83 76",
      "locate": {
        "address": "1 Le Hameau de la Châtaigneraie",
        "complement": "",
        "postalCode": "35680",
        "city": "DOMALAIN",
        "country": "France",
        "marker": "1",
        "geolocLat": "47.9972171",
        "geolocLng": "-1.2380603"
      }
    },
    {
      "email": "anthony.perrin@sos-bricolage.com ",
      "city": "DOMEVRE SUR DURBION (88)",
      "phone": "06 65 18 16 12",
      "locate": {
        "address": "16 Rue de Richardpont",
        "complement": "",
        "postalCode": "88330",
        "city": "Domèvre-sur-Durbion",
        "country": "France",
        "marker": "1",
        "geolocLat": "48.2816414",
        "geolocLng": "6.4734808"
      }
    },
    {
      "email": "georges.yalcin@sos-bricolage.com",
      "city": "DOMONT (95)",
      "phone": "06 87 56 11 25",
      "locate": {
        "address": "101 Rue André Nouet",
        "complement": "",
        "postalCode": "95330",
        "city": "Domont",
        "country": "France",
        "marker": "1",
        "geolocLat": "49.0260753",
        "geolocLng": "2.3359081"
      }
    },
    {
      "email": "nicolas.choblet@sos-bricolage.com",
      "city": "DROCOURT (78)",
      "phone": "06 21 67 00 33",
      "locate": {
        "address": "8 impasse du Mont Rôti",
        "complement": "",
        "postalCode": "78440",
        "city": "DROCOURT",
        "country": "",
        "marker": "1",
        "geolocLat": "49.0582348",
        "geolocLng": "1.7613682"
      }
    },
    {
      "email": "jp.alain@sos-bricolage.com",
      "city": "DRUMETAZ-CLARAFOND  (73)",
      "phone": "06 07 72 34 30",
      "locate": {
        "address": "45 Allée du Clos du Puits",
        "complement": "",
        "postalCode": "73420",
        "city": "Drumettaz-Clarafond",
        "country": "France",
        "marker": "1",
        "geolocLat": "45.6593013",
        "geolocLng": "5.915656499999999"
      }
    },
    {
      "email": "olivier.perrault@sos-bricolage.com",
      "city": "ELANCOURT (78)",
      "phone": "06 86 89 72 50",
      "locate": {
        "address": "20 avenue paul Cezanne",
        "complement": "",
        "postalCode": "78990",
        "city": "ELANCOURT",
        "country": "",
        "marker": "1",
        "geolocLat": "48.76875649999999",
        "geolocLng": "1.968581"
      }
    },
    {
      "email": "romain.poulizac@sos-bricolage.com",
      "city": "ELOYES (88)",
      "phone": "06 70 43 74 50",
      "locate": {
        "address": "18 Rue Leduc",
        "complement": "",
        "postalCode": "88510",
        "city": "Éloyes",
        "country": "France",
        "marker": "1",
        "geolocLat": "48.0976779",
        "geolocLng": "6.608658999999999"
      }
    },
    {
      "email": "benoit.lermusiaux@sos-bricolage.com ",
      "city": "ETOUY (60)",
      "phone": "06 26 78 23 57",
      "locate": {
        "address": "100 Rue Saint-Martin",
        "complement": "",
        "postalCode": "60600",
        "city": "Étouy",
        "country": "France",
        "marker": "1",
        "geolocLat": "49.41788159999999",
        "geolocLng": "2.3664212"
      }
    },
    {
      "email": "christophe.bresson@sos-bricolage.com",
      "city": "EULMONT (54)",
      "phone": "07 68 32 40 27",
      "locate": {
        "address": "705 route de nomeny ",
        "complement": "",
        "postalCode": "54690",
        "city": "eulmont ",
        "country": "",
        "marker": "1",
        "geolocLat": "48.7482394",
        "geolocLng": "6.2297535"
      }
    },
    {
      "email": "yves.trehard@sos-bricolage.com",
      "city": "EYBENS (38)",
      "phone": "07 57 84 49 82",
      "locate": {
        "address": "22 Allée Gaston Bachelard",
        "complement": "",
        "postalCode": "38320",
        "city": "Eybens",
        "country": "France",
        "marker": "1",
        "geolocLat": "45.1472997",
        "geolocLng": "5.7438349"
      }
    },
    {
      "email": "thierry.barozzi@sos-bricolage.com",
      "city": "FILLINGES (74)",
      "phone": "06 72 65 79 90",
      "locate": {
        "address": "84 Route de Chez Mermier",
        "complement": "",
        "postalCode": "74250",
        "city": "Fillinges",
        "country": "France",
        "marker": "1",
        "geolocLat": "46.1775789",
        "geolocLng": "6.3715858"
      }
    },
    {
      "email": "jerome.huet@sos-bricolage.com",
      "city": "FORT DE FRANCE (97)",
      "phone": "06 96 92 61 62",
      "locate": {
        "address": "276 Route de Balata",
        "complement": "",
        "postalCode": "97200",
        "city": "Fort-de-France",
        "country": "Martinique",
        "marker": "1",
        "geolocLat": "14.6255396",
        "geolocLng": "-61.0722542"
      }
    },
    {
      "email": "pascal.tolla@sos-bricolage.com",
      "city": "GANGES (34)",
      "phone": "06 11 79 57 85",
      "locate": {
        "address": "2 rue des Mimosas",
        "complement": "",
        "postalCode": "34190",
        "city": "GANGES",
        "country": "FRANCE",
        "marker": "1",
        "geolocLat": "43.9400014",
        "geolocLng": "3.718595"
      }
    },
    {
      "email": "laurent.crine@sos-bricolage.com",
      "city": "GANNAT (03)",
      "phone": "07 76 06 03 29",
      "locate": {
        "address": "12 Rue des Jonchères",
        "complement": "",
        "postalCode": "03800",
        "city": "Gannat",
        "country": "France",
        "marker": "1",
        "geolocLat": "46.0996338",
        "geolocLng": "3.1919373"
      }
    },
    {
      "email": "",
      "city": "GER (65)",
      "phone": "06 26 08 79 95 ",
      "locate": {
        "address": "6 Chemin Arremissant",
        "complement": "",
        "postalCode": "65100",
        "city": "GER",
        "country": "France",
        "marker": "1",
        "geolocLat": "43.0571353",
        "geolocLng": "-0.03954610000000001"
      }
    },
    {
      "email": "michael.piron@sos-bricolage.com",
      "city": "GERNELLE (08)",
      "phone": "06 89 94 67 05",
      "locate": {
        "address": "32 Rue du Muguet",
        "complement": "",
        "postalCode": "08440",
        "city": "Gernelle",
        "country": "France",
        "marker": "1",
        "geolocLat": "49.7664171",
        "geolocLng": "4.8138176"
      }
    },
    {
      "email": "dorian.labbe@sos-bricolage.com",
      "city": "GIGEAN (34)",
      "phone": "06 02 43 90 91",
      "locate": {
        "address": "8 Rue de la Magette",
        "complement": "",
        "postalCode": "34770",
        "city": "Gigean",
        "country": "France",
        "marker": "1",
        "geolocLat": "43.5007564",
        "geolocLng": "3.7059452"
      }
    },
    {
      "email": "aurelien.melet@sos-bricolage.com",
      "city": "GIGNAC (46)",
      "phone": "06 31 87 28 12",
      "locate": {
        "address": "169 Route des Clos",
        "complement": "",
        "postalCode": "46600",
        "city": "Gignac",
        "country": "France",
        "marker": "1",
        "geolocLat": "45.0095365",
        "geolocLng": "1.4686765"
      }
    },
    {
      "email": "patrice.monoury@sos-bricolage.com",
      "city": "GOUPIL-OTHON (27)",
      "phone": "07 70 02 96 81",
      "locate": {
        "address": "2 Rue de Bouquelon",
        "complement": "",
        "postalCode": "27170",
        "city": "Goupil-Othon",
        "country": "France",
        "marker": "1",
        "geolocLat": "49.122567",
        "geolocLng": "0.7811933"
      }
    },
    {
      "email": "cyrille.firmin@sos-bricolage.com",
      "city": "HALLOY (60)",
      "phone": "06 16 14 27 33",
      "locate": {
        "address": "9 Rue du Guidon (Petit Halloy )",
        "complement": "",
        "postalCode": "60210",
        "city": "Halloy",
        "country": "France",
        "marker": "1",
        "geolocLat": "49.6568846",
        "geolocLng": "1.927467"
      }
    },
    {
      "email": "",
      "city": "HURTIGHEIM (67)",
      "phone": "06 89 06 37 59",
      "locate": {
        "address": "24 Rue des Forgerons",
        "complement": "",
        "postalCode": "67117",
        "city": "HURTIGHEIM",
        "country": "France",
        "marker": "1",
        "geolocLat": "48.6162277",
        "geolocLng": "7.589809900000001"
      }
    },
    {
      "email": "frederic.bernard@sos-bricolage.com",
      "city": "INGUINIEL (56)",
      "phone": "07 80 96 60 34",
      "locate": {
        "address": "Kerihuel",
        "complement": "",
        "postalCode": "56240",
        "city": "Inguiniel",
        "country": "France",
        "marker": "1",
        "geolocLat": "47.950586",
        "geolocLng": "-3.225928"
      }
    },
    {
      "email": "benjamin.delmas@sos-bricolage.com",
      "city": "JUGEALS NAZARETH (19)",
      "phone": "06 12 59 34 45",
      "locate": {
        "address": "Route du Château,",
        "complement": "",
        "postalCode": "19500",
        "city": "Jugeals-Nazareth",
        "country": "FRANCE",
        "marker": "1",
        "geolocLat": "45.06755425763243",
        "geolocLng": "1.539802093749989"
      }
    },
    {
      "email": "sonia.courtois@sos-bricolage.com",
      "city": "KOEUR LA PETITE (55)",
      "phone": "07 57 08 31 35",
      "locate": {
        "address": "23 Rue Poiron",
        "complement": "",
        "postalCode": "55300",
        "city": "Kœur-la-Petite",
        "country": "France",
        "marker": "1",
        "geolocLat": "48.85558169999999",
        "geolocLng": "5.496637499999999"
      }
    },
    {
      "email": "xavier.lefebvre@sos-bricolage.com",
      "city": "LA CELLE SAINT CLOUD  (78).",
      "phone": "06 09 34 19 63",
      "locate": {
        "address": "41 Avenue Lily",
        "complement": "",
        "postalCode": "78170",
        "city": "La Celle-Saint-Cloud",
        "country": "France",
        "marker": "1",
        "geolocLat": "48.8443737",
        "geolocLng": "2.1450321"
      }
    },
    {
      "email": "bruno.fromentin@sos-bricolage.com",
      "city": "LA CELLE SAINT CLOUD (78)",
      "phone": "07 56 99 29 71",
      "locate": {
        "address": "16 Résidence du Bel Ebat",
        "complement": "",
        "postalCode": "78170",
        "city": "La Celle-Saint-Cloud",
        "country": "France",
        "marker": "1",
        "geolocLat": "48.8369241",
        "geolocLng": "2.1330258"
      }
    },
    {
      "email": "",
      "city": "LA GREVE SUR MIGNON (17)",
      "phone": "06 29 89 89 09 ",
      "locate": {
        "address": "15 Route de Courçon",
        "complement": "",
        "postalCode": "17170",
        "city": "La Grève-sur-Mignon",
        "country": "France",
        "marker": "1",
        "geolocLat": "46.250828",
        "geolocLng": "-0.7631053999999999"
      }
    },
    {
      "email": "emmanuel.joly@sos-bricolage.com",
      "city": "LA ROCHELLE (17)",
      "phone": "07 66 83 25 84",
      "locate": {
        "address": "36 Avenue du Champ de Mars",
        "complement": "",
        "postalCode": "17000",
        "city": "La Rochelle",
        "country": "France",
        "marker": "1",
        "geolocLat": "46.1693167",
        "geolocLng": "-1.1482351"
      }
    },
    {
      "email": "",
      "city": "LATRESNE (33)",
      "phone": "07 69 65 60 84",
      "locate": {
        "address": "10 Rue des Malbecs",
        "complement": "",
        "postalCode": "33360",
        "city": "Latresne",
        "country": "France",
        "marker": "1",
        "geolocLat": "44.787189",
        "geolocLng": "-0.4941619000000002"
      }
    },
    {
      "email": "frederic.houlle@sos-bricolage.com",
      "city": "LE MANS (72)",
      "phone": "07 86 03 96 15",
      "locate": {
        "address": "74 rue Henry Delagénière",
        "complement": "",
        "postalCode": "72000",
        "city": "LE MANS",
        "country": "",
        "marker": "1",
        "geolocLat": "48.0141693",
        "geolocLng": "0.2014755"
      }
    },
    {
      "email": "py.sebire@sos-bricolage.com",
      "city": "LE THEIL NOCENT (27)",
      "phone": "06 17 92 91 19",
      "locate": {
        "address": "8 Rue de la Mairie",
        "complement": "",
        "postalCode": "27230",
        "city": "Le Theil-Nolent",
        "country": "France",
        "marker": "1",
        "geolocLat": "49.1540833",
        "geolocLng": "0.5376757999999999"
      }
    },
    {
      "email": "frederic.schaaf@sos-bricolage.com",
      "city": "LEMUD (57)",
      "phone": "03 68 381 380",
      "locate": {
        "address": "4 rue de Metz",
        "complement": "",
        "postalCode": "57580",
        "city": "LEMUD",
        "country": "FRANCE",
        "marker": "1",
        "geolocLat": "49.03817309999999",
        "geolocLng": "6.365037399999999"
      }
    },
    {
      "email": "david.buriol@sos-bricolage.com",
      "city": "LES HERBIERS (85)",
      "phone": "07 64 07 29 19",
      "locate": {
        "address": "15 rue surmaine ",
        "complement": "",
        "postalCode": "85500",
        "city": "LES HERBIERS",
        "country": "",
        "marker": "1",
        "geolocLat": "46.8712181",
        "geolocLng": "-1.0216561"
      }
    },
    {
      "email": "alexandre.tocque@sos-bricolage.com",
      "city": "LIBOURNE (33)",
      "phone": "06 30 66 49 65",
      "locate": {
        "address": "23 Place Jean Moulin",
        "complement": "",
        "postalCode": "33500",
        "city": "Libourne",
        "country": "France",
        "marker": "1",
        "geolocLat": "44.91880450000001",
        "geolocLng": "-0.2427339"
      }
    },
    {
      "email": "mikael.evrard@sos-bricolage.com",
      "city": "LIGNEROLLES (27)",
      "phone": "06 99 77 60 36",
      "locate": {
        "address": "5 Bis Route de Saint Laurent",
        "complement": "",
        "postalCode": "27220",
        "city": "LIGNEROLLES",
        "country": "",
        "marker": "1",
        "geolocLat": "48.8454137",
        "geolocLng": "1.2796763"
      }
    },
    {
      "email": "damien.laisne@sos-bricolage.com",
      "city": "LILLE (59)",
      "phone": "06 10 08 85 63",
      "locate": {
        "address": "40 Rue de Gand",
        "complement": "",
        "postalCode": "59800",
        "city": "Lille",
        "country": "France",
        "marker": "1",
        "geolocLat": "50.6421037",
        "geolocLng": "3.0661592"
      }
    },
    {
      "email": "jimmy.provoost@sos-bricolage.com",
      "city": "LIZANT (86)",
      "phone": "07 66 57 66 49",
      "locate": {
        "address": "",
        "complement": "",
        "postalCode": "86400",
        "city": "Lizant",
        "country": "France",
        "marker": "1",
        "geolocLat": "46.07822399999999",
        "geolocLng": "0.2700279999999999"
      }
    },
    {
      "email": "sebastien.vincent@sos-bricolage.com",
      "city": "LURE (70)",
      "phone": "06 60 44 64 34",
      "locate": {
        "address": "43 Rue de Lorraine",
        "complement": "",
        "postalCode": "70200",
        "city": "Lure",
        "country": "France",
        "marker": "1",
        "geolocLat": "47.698591",
        "geolocLng": "6.4872305"
      }
    },
    {
      "email": "julien.bureau@sos-bricolage.com",
      "city": "MARCHEPRIME (33)",
      "phone": "06 14 37 03 61",
      "locate": {
        "address": "28 Rue du Val de l'Eyre",
        "complement": "",
        "postalCode": "33380",
        "city": "Marcheprime",
        "country": "France",
        "marker": "1",
        "geolocLat": "44.6878086",
        "geolocLng": "-0.8503531"
      }
    },
    {
      "email": "mickael.boulle@sos-bricolage.com",
      "city": "MARSAS (33)",
      "phone": "06 60 84 37 12",
      "locate": {
        "address": "8 Rue Armand Guindron",
        "complement": "",
        "postalCode": "33620",
        "city": "Marsas",
        "country": "France",
        "marker": "1",
        "geolocLat": "45.0679161",
        "geolocLng": "-0.3821678000000001"
      }
    },
    {
      "email": "jerome.robert@sos-bricolage.com",
      "city": "MARSEILLE (13)",
      "phone": "06 38 05 76 43",
      "locate": {
        "address": "134 Boulevard Paul Claudel",
        "complement": "",
        "postalCode": "13010",
        "city": "Marseille",
        "country": "France",
        "marker": "1",
        "geolocLat": "43.26812799999999",
        "geolocLng": "5.413609799999999"
      }
    },
    {
      "email": "david.levoy@sos-bricolage.com",
      "city": "MAULE (78)",
      "phone": "06 61 64 67 43",
      "locate": {
        "address": "28 Avenue Jean Jaurès",
        "complement": "",
        "postalCode": "78580",
        "city": "Maule",
        "country": "France",
        "marker": "1",
        "geolocLat": "48.9133353",
        "geolocLng": "1.867334"
      }
    },
    {
      "email": "",
      "city": "MAURON (56)",
      "phone": "06 58 26 89 07",
      "locate": {
        "address": "3 Avenue Jean Allain",
        "complement": "",
        "postalCode": "56430",
        "city": "Mauron",
        "country": "France",
        "marker": "1",
        "geolocLat": "48.0832841",
        "geolocLng": "-2.2821786"
      }
    },
    {
      "email": "david.verite@sos-bricolage.com",
      "city": "MAUZE SUR LE MIGNON (79)",
      "phone": "07 78 13 46 26",
      "locate": {
        "address": "48 Grand Rue",
        "complement": "",
        "postalCode": "79210",
        "city": "Mauzé-sur-le-Mignon",
        "country": "France",
        "marker": "1",
        "geolocLat": "46.19578550000001",
        "geolocLng": "-0.6680309999999999"
      }
    },
    {
      "email": "pierre.dhermant@sos-bricolage.com",
      "city": "MESNIL RAOUL (76)",
      "phone": "06 62 94 08 17",
      "locate": {
        "address": "121 Rue du Mesnil",
        "complement": "",
        "postalCode": "76520",
        "city": "Mesnil-Raoul",
        "country": "France",
        "marker": "1",
        "geolocLat": "49.388931",
        "geolocLng": "1.274209"
      }
    },
    {
      "email": "laurent.chevrel@sos-bricolage.com",
      "city": "MOELAN SUR MER (29)",
      "phone": "06 64 53 78 42",
      "locate": {
        "address": "33 Route de Kersaux",
        "complement": "",
        "postalCode": "29350",
        "city": "Moëlan-sur-Mer",
        "country": "France",
        "marker": "1",
        "geolocLat": "47.8147244",
        "geolocLng": "-3.6721637"
      }
    },
    {
      "email": "thierry.monier@sos-bricolage.com",
      "city": "MONTAGNE (38)",
      "phone": "06 23 34 45 35",
      "locate": {
        "address": "105 Impasse de la Croix de la Cave",
        "complement": "",
        "postalCode": "38160",
        "city": "Montagne",
        "country": "France",
        "marker": "1",
        "geolocLat": "45.14085722830803",
        "geolocLng": "5.192584533935536"
      }
    },
    {
      "email": "olivier.toniolo@sos-bricolage.com",
      "city": "MONTCLUS (30)",
      "phone": "06 64 65 31 63",
      "locate": {
        "address": "Chemin de l'Entremont",
        "complement": "",
        "postalCode": "30630",
        "city": "Montclus",
        "country": "France",
        "marker": "1",
        "geolocLat": "44.265833",
        "geolocLng": "4.4281574"
      }
    },
    {
      "email": "laurent.pyrame@sos-bricolage.com",
      "city": "MONTEREAU FAULT YONNE (77)",
      "phone": "06 81 55 69 29",
      "locate": {
        "address": "13 Rue Etienne Thibault",
        "complement": "",
        "postalCode": "77130",
        "city": "Montereau-Fault-Yonne",
        "country": "France",
        "marker": "1",
        "geolocLat": "48.38239",
        "geolocLng": "2.9484571"
      }
    },
    {
      "email": "christopher.garnier@sos-bricolage.com",
      "city": "MONTREUIL (28)",
      "phone": "06 33 81 49 95",
      "locate": {
        "address": "8 Route de Muzy",
        "complement": "",
        "postalCode": "28500",
        "city": "Montreuil",
        "country": "France",
        "marker": "1",
        "geolocLat": "48.7781426",
        "geolocLng": "1.3609885"
      }
    },
    {
      "email": "jean.dureux@sos-bricolage.com",
      "city": "MOUANS SARTOUX  (06)",
      "phone": "06 88 94 18 49",
      "locate": {
        "address": "411 Corniche Paul Bénard",
        "complement": "",
        "postalCode": "06370",
        "city": "Mouans-Sartoux",
        "country": "France",
        "marker": "1",
        "geolocLat": "43.6242561",
        "geolocLng": "6.9727408"
      }
    },
    {
      "email": "christophe.sapin@sos-bricolage.com",
      "city": "MURS ERIGNE (49)",
      "phone": "06 65 10 82 49",
      "locate": {
        "address": "17A rue de la Chapelle",
        "complement": "",
        "postalCode": "49610 ",
        "city": "MURS ERIGNE",
        "country": "FRANCE",
        "marker": "1",
        "geolocLat": "47.3967973",
        "geolocLng": "-0.5465892"
      }
    },
    {
      "email": "  emmanuel.blanloeil@sos-bricolage.com",
      "city": "Montrevault-sur-Èvre   (49)",
      "phone": "06 49 62 00 60",
      "locate": {
        "address": "1 Allée des Coteaux ",
        "complement": "La Boissière-sur-Èvre",
        "postalCode": "49110",
        "city": "Montrevault-sur-Èvre",
        "country": "France",
        "marker": "1",
        "geolocLat": "47.261731",
        "geolocLng": "-1.0465942"
      }
    },
    {
      "email": "laurent.carpentier@sos-bricolage.com",
      "city": "OUROUX  SUR SAONE (71)",
      "phone": "06 22 14 17 99 ",
      "locate": {
        "address": "39 Rue de la Verne",
        "complement": "",
        "postalCode": "71370",
        "city": "Ouroux-sur-Saône",
        "country": "France",
        "marker": "1",
        "geolocLat": "46.7353209",
        "geolocLng": "4.927214000000001"
      }
    },
    {
      "email": "didier.lechaux@sos-bricolage.com",
      "city": "PACE (35)",
      "phone": "0769722610",
      "locate": {
        "address": "l'epine",
        "complement": "",
        "postalCode": "35740",
        "city": "pace",
        "country": "",
        "marker": "1",
        "geolocLat": "48.16567",
        "geolocLng": "-1.763329"
      }
    },
    {
      "email": "rui.rocha@sos-bricolage.com",
      "city": "PAU (64)",
      "phone": "06 51 60 50 99",
      "locate": {
        "address": "1 Rue des Jardins du Laü",
        "complement": "no 31",
        "postalCode": "64000",
        "city": "Pau",
        "country": "France",
        "marker": "1",
        "geolocLat": "43.3151204",
        "geolocLng": "-0.3496525"
      }
    },
    {
      "email": "regis.ortiger@sos-bricolage.com",
      "city": "PELOUSEY (25)",
      "phone": "07 67 23 81 52",
      "locate": {
        "address": "2 Rue du Chanot",
        "complement": "",
        "postalCode": "25170",
        "city": "Pelousey",
        "country": "France",
        "marker": "1",
        "geolocLat": "47.276863",
        "geolocLng": "5.926480799999999"
      }
    },
    {
      "email": "david.bernard@sos-bricolage.com",
      "city": "PLUVET (21)",
      "phone": "06 62 05 67 76",
      "locate": {
        "address": "26 Rue de la Cour",
        "complement": "",
        "postalCode": "21110",
        "city": "Pluvet",
        "country": "France",
        "marker": "1",
        "geolocLat": "47.20601229999999",
        "geolocLng": "5.2612405"
      }
    },
    {
      "email": "patrick.murgue@sos-bricolage.com",
      "city": "POITIERS (86)",
      "phone": "06 60 95 34 63",
      "locate": {
        "address": "3 Avenue du Tiers État",
        "complement": "",
        "postalCode": "86000",
        "city": "Poitiers",
        "country": "France",
        "marker": "1",
        "geolocLat": "46.59351059999999",
        "geolocLng": "0.3796647"
      }
    },
    {
      "email": "samuel.rochette@sos-bricolage.com ",
      "city": "POMMEUSE (77)",
      "phone": "06 19 02 23 50",
      "locate": {
        "address": "19 Avenue du Général Huerne",
        "complement": "",
        "postalCode": "77515",
        "city": "Pommeuse",
        "country": "France",
        "marker": "1",
        "geolocLat": "48.8160399",
        "geolocLng": "3.0152036"
      }
    },
    {
      "email": "fabrice.place@sos-bricolage.com",
      "city": "PRAYSSAS (47)",
      "phone": "07 56 98 14 18",
      "locate": {
        "address": "Lieu dit Duc",
        "complement": "",
        "postalCode": "47360",
        "city": "Prayssas",
        "country": "France",
        "marker": "1",
        "geolocLat": "44.2996868",
        "geolocLng": "0.4998772000000001"
      }
    },
    {
      "email": "pascal.lelievre@sos-bricolage.com",
      "city": "PUYMOYEN (16)",
      "phone": "07 49 11 03 08",
      "locate": {
        "address": "12 Résidence le Cottage",
        "complement": "",
        "postalCode": "16400",
        "city": "Puymoyen",
        "country": "France",
        "marker": "1",
        "geolocLat": "45.6224553",
        "geolocLng": "0.1615822"
      }
    },
    {
      "email": "jc.michot@sos-bricolage.com",
      "city": "RECURT (65)",
      "phone": "07 70 05 39 28",
      "locate": {
        "address": "3 Bis Route de Lannemezan",
        "complement": "",
        "postalCode": "65330",
        "city": "RECURT",
        "country": "",
        "marker": "1",
        "geolocLat": "43.1983167",
        "geolocLng": "0.4331573"
      }
    },
    {
      "email": "jp.binet@sos-bricolage.com",
      "city": "ROMORANTIN (41)",
      "phone": "06 65 24 16 01",
      "locate": {
        "address": "41 Rue Creuse",
        "complement": "",
        "postalCode": "41200",
        "city": "Romorantin-Lanthenay",
        "country": "France",
        "marker": "1",
        "geolocLat": "47.3624593",
        "geolocLng": "1.7544257"
      }
    },
    {
      "email": "julien.terra@sos-bricolage.com",
      "city": "SAINT APOLLINAIRE (21)",
      "phone": "06 61 95 87 41",
      "locate": {
        "address": "20 Rue Général de Gaulle",
        "complement": "",
        "postalCode": "21850",
        "city": "SAINT-APOLLINAIRE",
        "country": "France",
        "marker": "1",
        "geolocLat": "47.3304144",
        "geolocLng": "5.0856455"
      }
    },
    {
      "email": "jm.anjolras@sos-bricolage.com",
      "city": "SAINT BLAISE DU BUIS (38)",
      "phone": "06 63 36 92 41",
      "locate": {
        "address": "584 Route de Planche Cattin",
        "complement": "",
        "postalCode": "38140",
        "city": "SAINT BLAISE DU BUIS",
        "country": "France",
        "marker": "1",
        "geolocLat": "45.387186",
        "geolocLng": "5.5097328"
      }
    },
    {
      "email": "jc.armand@sos-bricolage.com",
      "city": "SAINT DENIS  (97)",
      "phone": "06 92 39 49 50",
      "locate": {
        "address": "120 Route de Mare A Goyaves",
        "complement": "",
        "postalCode": "97433",
        "city": "Salazie",
        "country": "Réunion",
        "marker": "1",
        "geolocLat": "-21.0216349",
        "geolocLng": "55.53590380000001"
      }
    },
    {
      "email": "vincent.gatouillat@sos-bricolage.com",
      "city": "SAINT MICHEL L&#039;OBSERVATOIRE (04)",
      "phone": "+33 6 82 30 77 46",
      "locate": {
        "address": "Route de Sainte-Anne",
        "complement": "",
        "postalCode": "04870",
        "city": "Saint-Michel-l&#039;Observatoire",
        "country": "France",
        "marker": "1",
        "geolocLat": "43.90455",
        "geolocLng": "5.717426"
      }
    },
    {
      "email": "jerome.presson@sos-bricolage.com",
      "city": "SAINT OUEN DES BESACES  (14)",
      "phone": "06 01 02 04 37",
      "locate": {
        "address": "le beau poret ",
        "complement": "",
        "postalCode": "14350",
        "city": "saint ouen des besaces ",
        "country": "",
        "marker": "1",
        "geolocLat": "49.02637",
        "geolocLng": "-0.821812"
      }
    },
    {
      "email": "benjamin.point-dumont@sos-bricolage.com",
      "city": "SAINT SIMEON DE BRESSIEUX  (38)",
      "phone": "07 66 74 96 20",
      "locate": {
        "address": "160 Chemin du Vert",
        "complement": "",
        "postalCode": "38870",
        "city": "SAINT SIMEON DE BRESSIEUX",
        "country": "FRANCE",
        "marker": "1",
        "geolocLat": "45.33202929999999",
        "geolocLng": "5.288862"
      }
    },
    {
      "email": "jerome.payet@sos-bricolage.com",
      "city": "SAINT-PIERRE (97)",
      "phone": "06 92 08 30 14",
      "locate": {
        "address": "36 Itinéraire De La Soie",
        "complement": "",
        "postalCode": "97410",
        "city": "Saint-Pierre",
        "country": "La Réunion",
        "marker": "1",
        "geolocLat": "-21.3387741",
        "geolocLng": "55.4865315"
      }
    },
    {
      "email": "patrick.leflament@sos-bricolage.com",
      "city": "SAINTE HERMINE (85)",
      "phone": "07 67 82 93 87",
      "locate": {
        "address": "Le Bois Rond",
        "complement": "",
        "postalCode": "85210",
        "city": "Sainte-Hermine",
        "country": "France",
        "marker": "1",
        "geolocLat": "46.5595234",
        "geolocLng": "-1.0922067"
      }
    },
    {
      "email": "patrice.matha@sos-bricolage.com",
      "city": "SALERNES (83)",
      "phone": "07 69 68 71 52",
      "locate": {
        "address": "289 chemin Bellandes",
        "complement": "",
        "postalCode": "83690",
        "city": "SALERNES",
        "country": "France",
        "marker": "1",
        "geolocLat": "43.5635713",
        "geolocLng": "6.2286713"
      }
    },
    {
      "email": "olivier.dhuesme@sos-bricolage.com",
      "city": "SALON DE PROVENCE (13)",
      "phone": "06 63 65 32 90",
      "locate": {
        "address": "1765 Chemin de la Grand'carraire",
        "complement": "",
        "postalCode": "13300",
        "city": "Salon-de-Provence",
        "country": "France",
        "marker": "1",
        "geolocLat": "43.6557849",
        "geolocLng": "5.064962"
      }
    },
    {
      "email": "eric.lemee@sos-bricolage.com",
      "city": "SEVRAN (93)",
      "phone": "07 69 92 69 85",
      "locate": {
        "address": "50 avenue la renaissance ",
        "complement": "",
        "postalCode": "93270",
        "city": "SEVRAN",
        "country": "France ",
        "marker": "1",
        "geolocLat": "48.9391903",
        "geolocLng": "2.5141841"
      }
    },
    {
      "email": "jp.lazerges@sos-bricolage.com",
      "city": "ST LUBIN DE LA HAYE (28)",
      "phone": "06 50 48 34 87",
      "locate": {
        "address": "9 rue de la Tour",
        "complement": "Hameau Richebourg",
        "postalCode": "28410",
        "city": "ST LUBIN DE LA HAYE",
        "country": "",
        "marker": "1",
        "geolocLat": "48.8145586",
        "geolocLng": "1.5571973"
      }
    },
    {
      "email": "gwendal.heraud@sos-bricolage.com",
      "city": "SURGERES (17)",
      "phone": "07 62 60 00 65",
      "locate": {
        "address": "12 Rue Bois Fontaine",
        "complement": "",
        "postalCode": "17700",
        "city": "Surgères",
        "country": "France",
        "marker": "1",
        "geolocLat": "46.1129581",
        "geolocLng": "-0.7519908"
      }
    },
    {
      "email": "jp.alain@sos-bricolage.com",
      "city": "Saint-Mathieu-de-Tréviers (34)",
      "phone": "06 07 72 34 30",
      "locate": {
        "address": "Avenue Guillaume Pellicier",
        "complement": "",
        "postalCode": "34270",
        "city": "Saint-Mathieu-de-Tréviers",
        "country": "France",
        "marker": "1",
        "geolocLat": "43.7623659",
        "geolocLng": "3.8673652"
      }
    },
    {
      "email": "david.gaca@sos-bricolage.com",
      "city": "THIONVILLE (57)",
      "phone": "03 82 58 26 40 - 07 83 20 01 60",
      "locate": {
        "address": "8 Rue des jardins Fleuris",
        "complement": "",
        "postalCode": "57100",
        "city": "THIONVILLE",
        "country": "",
        "marker": "1",
        "geolocLat": "49.3554247",
        "geolocLng": "6.1136669"
      }
    },
    {
      "email": "patrick.desuzinge@sos-bricolage.com",
      "city": "THONON LES BAINS (74)",
      "phone": "06 45 44 05 29",
      "locate": {
        "address": "26 bis avenue Jules Ferry",
        "complement": "",
        "postalCode": "74200",
        "city": "THONON LES BAINS",
        "country": "",
        "marker": "1",
        "geolocLat": "46.3746732",
        "geolocLng": "6.4854584"
      }
    },
    {
      "email": "aurelien.vanmoer@sos-bricolage.com",
      "city": "TOURCOING (59)",
      "phone": "07 68 34 81 50",
      "locate": {
        "address": "15 Rue Edouard Lalo",
        "complement": "",
        "postalCode": "59200",
        "city": "Tourcoing",
        "country": "France",
        "marker": "1",
        "geolocLat": "50.7349893",
        "geolocLng": "3.1702239"
      }
    },
    {
      "email": "arnaud.gaucher@sos-bricolage.com",
      "city": "TROISGOTS / CONDE SUR VIRE  (50)",
      "phone": "06 44 03 11 47",
      "locate": {
        "address": "La Frangelière ",
        "complement": "",
        "postalCode": "50420",
        "city": "Troisgôts / Condé sur Vire ",
        "country": "",
        "marker": "1",
        "geolocLat": "49.0120193",
        "geolocLng": "-1.0716432"
      }
    },
    {
      "email": "guillaume.molineau@sos-bricolage.com",
      "city": "VENDOME (41)",
      "phone": "06 07 25 60 02",
      "locate": {
        "address": "970 rue de la Forêt",
        "complement": "",
        "postalCode": "41100",
        "city": "VENDOME",
        "country": "",
        "marker": "1",
        "geolocLat": "47.8176734",
        "geolocLng": "1.0588121"
      }
    },
    {
      "email": "alban.omont@sos-bricolage.com",
      "city": "VILLAINVILLE (76)",
      "phone": "07 66 68 49 93",
      "locate": {
        "address": "7 Rue de la Forge",
        "complement": "",
        "postalCode": "76280",
        "city": "Villainville",
        "country": "France",
        "marker": "1",
        "geolocLat": "49.66054219999999",
        "geolocLng": "0.245017"
      }
    },
    {
      "email": "thierry.mouny@sos-bricolage.com",
      "city": "VIX (85)",
      "phone": "06 17 20 85 84",
      "locate": {
        "address": "3 Rue de la Touchantee",
        "complement": "",
        "postalCode": "85770",
        "city": "Vix",
        "country": "France",
        "marker": "1",
        "geolocLat": "46.3547591",
        "geolocLng": "-0.8507745999999999"
      }
    },
    {
      "email": "herve.guiot@sos-bricolage.com",
      "city": "WESTHOUSE-MARMOUTIER (67)",
      "phone": "06 43 88 99 46",
      "locate": {
        "address": "3A rue des Aubebines",
        "complement": "",
        "postalCode": "67440",
        "city": "WESTHOUSE-MARMOUTIER",
        "country": "",
        "marker": "1",
        "geolocLat": "48.6863876",
        "geolocLng": "7.451908100000001"
      }
    }
  ]