import { teal } from "@mui/material/colors";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  opacity: 100,
  commune: null,
  prelevements: [],
  doubleClickLng: null,
  doubleClickLat: null,
};

const heatmapSlice = createSlice({
  name: "heatmap",
  initialState,
  reducers: {
    setOpacity(state, action) {
      state.opacity = action.payload;
    },
    setCommune(state, action) {
      state.commune = action.payload;
    },
    setPrelevements(state, action) {
      state.prelevements = action.payload;
    },
    appendPrelevement(state, action) {
      state.prelevements = [...state.prelevements, action.payload];
    },
    appendPrelevements(state, action) {
      state.prelevements = [...state.prelevements, ...action.payload];
    },
    setDoubleClickLngLat(state, action) {
      state.doubleClickLng = action.payload[0];
      state.doubleClickLat = action.payload[1];
    },
  },
});

export const {
  setOpacity,
  setCommune,
  setPrelevements,
  appendPrelevement,
  appendPrelevements,
  setDoubleClickLngLat,
} = heatmapSlice.actions;

export default heatmapSlice.reducer;
