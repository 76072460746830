import React, { useRef, useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Navbar from "../components/navbar";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const Voip = () => {
    const [personalDetails, setPersonalDetails] = useState(null);
    const [outboundNumbers, setOutboundNumbers] = useState([]);

    useEffect(() => {
        document.title = "Foundy"
        /* global fetch */
        fetch(
            '/api/v1/voip/getPersonalDetails'
        )
            .then(resp => resp.json())
            .then(json => setPersonalDetails(json))
            .catch(err => console.error('Could not load data', err)); // eslint-disable-line

        /* global fetch */
        fetch( // async
            '/api/v1/outboundnumber/getPersonalOutboundNumbers'
        )
            .then(resp => resp.json())
            .then(json => setOutboundNumbers(json))
            .catch(err => console.error('Could not load data', err)); // eslint-disable-line
    }, []);

    const handleOutboundChange = (event) => {
        var details = { "phoneNumber": event.target.value };
    
        fetch("/api/v1/voip/changePersonalOutboundNumber", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(details)
        })
        .then( async (response) => {
            let data = await response;
            console.log(data);
            setPersonalDetails({...personalDetails, "OutboundCallerId": event.target.value});
        });
    };

    return (
        <div className="App">
            <Navbar />
            <Grid container
                direction="column"
                justifyContent="center"
                alignItems="center" 
            >
                <Grid item xs={6}>
                    <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                        Utilisateur 3CX
                    </Typography>
                    { personalDetails ? (
                        <List>
                            <ListItem
                            secondaryAction={
                                <IconButton edge="end" aria-label="activate" onClick={() => {disableUser(v.email)}}>
                                    <RemoveCircleOutlineIcon />
                                </IconButton>
                            }
                            >
                            <ListItemText
                                primary={ personalDetails.FirstName + " " + personalDetails.LastName }
                                secondary={ personalDetails.Email + " - " + personalDetails.Membership }
                            />
                            <FormControl>
                                <InputLabel id="role-select-label">Numéro outbound</InputLabel>
                                <Select
                                    labelId="role-select-label"
                                    id="role-select"
                                    value={ personalDetails.OutboundCallerId }
                                    label="Numéro outbound"
                                    onChange={(e) => handleOutboundChange(e)}
                                >
                                    {
                                        outboundNumbers.map(number => (
                                            <MenuItem value={number.outboundNumber}>{number.outboundNumber.match(/.{1,2}/g).join(" ")}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            </ListItem>
                        </List>
                    ) : "" }
                </Grid>
            </Grid>
        </div>
    );
};

export default Voip;