import { teal } from "@mui/material/colors";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  partners: [],
  disabledList: [],
  selectedPartner: null,
  selectedPartnerComments: null,
  foundAtLeastOneInstaller: null,
  foundInstallers: null,
  searchStep: 1,
  nafRadius: null,
  disabledPartners: null,
  failedPartners: [],
  searchType: "address",
  showDisabledPartners: true,
  showConventionnedPartners: true,
  showNonConventionnedPartners: true,
  showAquaPartners: true,
};

const partnerSlice = createSlice({
  name: "partners",
  initialState,
  reducers: {
    addPartners(state, action) {
      state.partners = [...state.partners, ...action.payload];
    },
    setPartners(state, action) {
      state.partners = action.payload;
    },
    setSearchType(state, action) {
      state.searchType = action.payload;
    },
    removeNafAndExternalPartners(state, action) {
      // partner.origin != 'naf' && partner.origin != 'sosbricolage' && partner.origin != 'pentair'
      state.partners = state.partners.filter(
        (partner) => partner.origin == "colors" || partner.origin == "odoo"
      );
    },
    addFailedPartners(state, action) {
      state.failedPartners = [...state.failedPartners, ...action.payload];
    },
    setFailedPartners(state, action) {
      state.failedPartners = action.payload;
    },
    setFoundInstallers(state, action) {
      //console.log("trouvé, " + action.payload);
      state.foundInstallers = action.payload;
      if (action.payload == null) {
        state.partners = [
          ...state.partners.map((obj) => {
            if (obj.costInstallateur) obj["costInstallateur"] = null;
            return obj;
          }),
        ];
        state.searchStep = 1;
      } else if (action.payload.length > 0) {
        state.foundAtLeastOneInstaller = true;
      } else {
        state.foundAtLeastOneInstaller = false;
        if (state.searchStep < 7) state.searchStep += 1;
      }
    },
    removeFoundInstaller(state, action) {
      state.foundInstallers = state.foundInstallers.filter(
        (elem) =>
          elem.pointInstallateur.properties.siret != action.payload.siret
      );
    },
    replaceFoundInstallerIsochrone(state, action) {
      var foundInstaller = state.foundInstallers.find(
        (elem) =>
          elem.pointInstallateur.properties.siret ==
          action.payload.installateur.pointInstallateur.properties.siret
      );
      foundInstaller["isochroneInstallateur"] = action.payload.isochrone;
    },
    updateTimeCostForInstaller(state, action) {
      let foundPartner = state.partners.find(
        (elem) => elem.siret == action.payload.siret
      );
      if (foundPartner)
        foundPartner["costInstallateur"] = action.payload.costInstallateur;
    },
    addFoundInstallers(state, action) {
      state.foundAtLeastOneInstaller = true;

      if (state.foundInstallers === null) {
        state.foundInstallers = [action.payload];
      } else {
        state.foundInstallers = [...state.foundInstallers, action.payload];
      }
    },
    disablePartners(state, action) {
      state.disabledPartners = action.payload;
      state.partners = [
        ...state.partners.map((p) => {
          action.payload.map((dp) => {
            if (dp.siret + "" == p.siret) {
              p.installing = dp.installing;
              p.selling = dp.selling;
              p.installingRefusalExpirationDate =
                dp.installingRefusalExpirationDate;
            }
          });

          return p;
        }),
      ];
    },
    setShowDisabledPartners(state, action) {
      state.showDisabledPartners = action.payload;
    },
    setShowConventionnedPartners(state, action) {
      state.showConventionnedPartners = action.payload;
    },
    setShowNonConventionnedPartners(state, action) {
      state.showNonConventionnedPartners = action.payload;
    },
    setShowAquaPartners(state, action) {
      state.showAquaPartners = action.payload;
    },
    addPartnersAndAvoidDuplicates(state, action) {
      state.partners = [
        ...state.partners,
        ...action.payload.map((item) => {
          if (
            state.partners.find(
              (p) => p.siret == item.siret && p.origin != item.origin
            ) != null
          ) {
            item.duplicate = true;
          }
          return item;
        }),
      ];
    },
    setPartnerInstalling(state, action) {
      state.partners = [
        ...state.partners.map((p) => {
          if (p.siret == action.payload.siret) {
            p.installing = action.payload.installing;
            p.installingRefusalExpirationDate =
              action.payload.installingRefusalExpirationDate;
            state.selectedPartner = p;
          }
          return p;
        }),
      ];
      if (state.foundInstallers && state.foundInstallers.length > 0) {
        state.foundInstallers = [
          ...state.foundInstallers.map((p) => {
            if (p.pointInstallateur.properties.siret == action.payload.siret) {
              p.pointInstallateur.properties.installing =
                action.payload.installing;
              p.pointInstallateur.properties.installingRefusalExpirationDate =
                action.payload.installingRefusalExpirationDate;
            }
            return p;
          }),
        ];
      }
    },
    setPartnerSelling(state, action) {
      state.partners = [
        ...state.partners.map((p) => {
          if (p.siret == action.payload.siret) {
            p.selling = action.payload.selling;
            state.selectedPartner = p;
          }
          return p;
        }),
      ];
      if (state.foundInstallers && state.foundInstallers.length > 0) {
        state.foundInstallers = [
          ...state.foundInstallers.map((p) => {
            if (p.pointInstallateur.properties.siret == action.payload.siret) {
              p.pointInstallateur.properties.selling = action.payload.selling;
            }
            return p;
          }),
        ];
      }
    },
    setSearchStep(state, action) {
      state.searchStep = action.payload;
    },
    setNafRadius(state, action) {
      state.nafRadius = action.payload;
    },
    incrementNafRadius(state, action) {
      state.nafRadius += action.payload;
    },
    setSelectedPartner(state, action) {
      state.selectedPartner = action.payload;
    },
    setSelectedPartnerComments(state, action) {
      state.selectedPartnerComments = action.payload;
    },
    setFoundAtLeastOneInstaller(state, action) {
      state.foundAtLeastOneInstaller = action.payload;
    },
  },
});

export const {
  addPartners,
  addPartnersAndAvoidDuplicates,
  setPartners,
  removeNafAndExternalPartners,
  setFoundInstallers,
  addFoundInstallers,
  replaceFoundInstallerIsochrone,
  setSearchStep,
  disablePartners,
  setPartnerInstalling,
  setPartnerSelling,
  setNafRadius,
  incrementNafRadius,
  setSelectedPartner,
  setSelectedPartnerComments,
  setFailedPartners,
  addFailedPartners,
  setFoundAtLeastOneInstaller,
  removeFoundInstaller,
  updateTimeCostForInstaller,
  setSearchType,
  setShowDisabledPartners,
  setShowConventionnedPartners,
  setShowNonConventionnedPartners,
  setShowAquaPartners,
} = partnerSlice.actions;

export default partnerSlice.reducer;
