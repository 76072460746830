import React, { useReducer } from "react";
import { Grid,Paper, Avatar, TextField, Button, Typography,Link,Dialog,Alert,AlertTitle } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from 'react-router-dom';
import {ReactComponent as FoundyLogo} from '../logo.svg'

const LoginForm=()=>{
    const navigate = useNavigate();
    const [formInput, setFormInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
          username: "",
          password: ""
        }
    );
    const handleSubmit = evt => {
        evt.preventDefault();
    
        let data = { formInput };
        var details = { "firstName": data.formInput.firstName, "lastName": data.formInput.lastName, "email": data.formInput.email, "password": data.formInput.password };
    
        fetch("/api/v1/registration", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(details)
        })
          .then( async (response) => {
            let data = await response;
            navigate("/login");
          });
    };
    const handleInput = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        setFormInput({ [name]: newValue });
    };
    const paperStyle={padding :20,width:280, margin:"20px auto"}
    const avatarStyle={backgroundColor:'#1bbd7e'}
    const btnstyle={margin:'8px 0'}

    const [open, setOpen] = React.useState(false);

    const handleDialog = () => {
      setOpen(!open);
    };

    return(
        <Grid>
            <Paper elevation={10} style={paperStyle}>
                <Grid align='center'>
                     <Avatar style={avatarStyle}><LockOutlinedIcon/></Avatar>
                    <FoundyLogo style={{width: "200px"}} />
                </Grid>
                <Dialog open={open} onClose={handleDialog}>
                    <Alert severity="error">
                    <AlertTitle>Erreur</AlertTitle>
                        Identifiants incorrects ou compte désactivé. Veuillez réessayer
                    </Alert>
                </Dialog>
                <form onSubmit={handleSubmit}>
                    <TextField label='Nom' name='lastName' onChange={handleInput} placeholder='Entrez nom' fullWidth required/>
                    <TextField label='Prénom' name='firstName' onChange={handleInput} placeholder='Entrez prénom' fullWidth required/>
                    <TextField label='Email' name='email' onChange={handleInput} placeholder='Entrez email' fullWidth required/>
                    <TextField label='Mot de passe' name='password' onChange={handleInput} placeholder='Entrez mot de passe' type='password' fullWidth required/>
                    <Button type='submit' color='primary' variant="contained" style={btnstyle} fullWidth>S'inscrire</Button>
                </form>
            </Paper>
        </Grid>
    )
}

export default LoginForm
