import HowToRegIcon from '@mui/icons-material/HowToReg';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useState, useEffect } from "react";
import Navbar from "../components/navbar";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { FormControl, InputLabel, Select, MenuItem, Checkbox } from '@mui/material';
import { TextField, Button } from '@mui/material'
import { useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

const Admin = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState(null);
    const [outboundNumbers, setOutboundNumbers] = useState([]);
    const [formInput, setFormInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
          phoneNumber: ""
        }
    );

    useEffect(() => {
        document.title = "Foundy | Administration"
        /* global fetch */
        fetch( // async
            '/api/v1/admin/getUsers'
        )
            .then(resp => resp.json())
            .then(json => setUsers(json))
            .catch(err => console.error('Could not load data', err)); // eslint-disable-line

        /* global fetch */
        fetch( // async
        '/api/v1/outboundnumber/getOutboundNumbers'
        )
            .then(resp => resp.json())
            .then(json => setOutboundNumbers(json))
            .catch(err => console.error('Could not load data', err)); // eslint-disable-line
    }, []);

    function enableUser(email) {
        fetch( // async
            '/api/v1/admin/enable?email=' + email
        )
        .then(resp => { // TODO change output to JSON in back-end
            if(resp) { //if(resp == '1') {
                setUsers(users.map((item) => { 
                    if(item.email === email) {
                        item.enabled = true;
                    }
                    return item;
                }));
            }
        })
        .catch(err => console.error('Could not load data', err)); // eslint-disable-line
    }

    function disableUser(email) {
        fetch( // async
            '/api/v1/admin/disable?email=' + email
        )
        .then(resp => { // TODO change output to JSON in back-end
            if(resp) { //if(resp == '1') {
                setUsers(users.map((item) => { 
                    if(item.email === email) {
                        item.enabled = false;
                    }
                    return item;
                }));
            }
        })
        .catch(err => console.error('Could not load data', err)); // eslint-disable-line
    }


    const handleRoleChange = (event, email) => {
        var details = { "email": email, "role": event.target.value };
    
        fetch("/api/v1/admin/changeUserRole", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(details)
        })
        .then( async (response) => {
            let data = await response;
            setUsers(users.map((item) => { 
                if(item.email === email) {
                    item.appUserRole = event.target.value;
                }
                return item;
            }));
        });
    };

    const handleSubmit = evt => {
        evt.preventDefault();

        var data = { formInput };

        if(data.formInput.phoneNumber == "") {
            return;
        }

        var details = { "phoneNumber": data.formInput.phoneNumber };
    
        fetch("/api/v1/outboundnumber/addOutboundNumber", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(details)
        })
          .then( async (response) => {
            let data = await response;
            setOutboundNumbers([...outboundNumbers, {'outboundNumber': formInput.phoneNumber}]);
          });
    };
    const handleInput = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        setFormInput({ [name]: newValue });
    };
    const btnstyle={margin:'8px 0'}

    const handleOutboundChange = (event,email,outboundNbs) => {
        const {
          target: { value },
        } = event;

        console.log(value);
        var action = "assign";

        if(outboundNbs.findIndex((o) => o.outboundNumber === value) >= 0) {
            action = "deassign";
        }

        console.log("action " + action);

        var details = { "phoneNumber": value, "email": email };
    
        fetch("/api/v1/outboundnumber/"+action+"OutboundNumberToUser", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(details)
        })
          .then( async (response) => {
            let data = await response;
            setUsers(users.map((item) => { 
                if(item.email === email) {
                    if(action == "assign") {
                        item.outboundNumbers = [...item.outboundNumbers, {'outboundNumber': value}];
                    } else {
                        item.outboundNumbers = item.outboundNumbers.filter(oNb => (oNb.outboundNumber != value));
                    }
                    
                }
                return item;
            }));
          });
    };

    return (
        <div className="App">
            <Navbar />
            <Grid container
                direction="column"
                justifyContent="center"
                alignItems="center" 
            >
                <Grid item xs={6}>
                    <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                        Utilisateurs non activés
                    </Typography>
                    { users ? (
                        <List>
                        { users.filter(item => item.enabled === false).map((v) => (
                            <ListItem
                            secondaryAction={
                                <IconButton edge="end" aria-label="activate" onClick={() => {enableUser(v.email)}}>
                                    <HowToRegIcon />
                                </IconButton>
                            }
                            >
                            <ListItemText
                                primary={ v.firstName + " " + v.lastName }
                                secondary={ v.email }
                            />
                            </ListItem>
                        )) }
                        </List>
                    ) : "" }
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                        Utilisateurs activés
                    </Typography>
                    { users ? (
                        <List>
                        { users.filter(item => item.enabled === true).map((v) => (
                            <ListItem
                            secondaryAction={
                                <IconButton edge="end" aria-label="activate" onClick={() => {disableUser(v.email)}}>
                                    <RemoveCircleOutlineIcon />
                                </IconButton>
                            }
                            >
                                <ListItemText
                                    primary={ v.firstName + " " + v.lastName }
                                    secondary={ v.email + " - " + v.appUserRole }
                                />
                                <FormControl>
                                    <InputLabel id="role-select-label">Rôle</InputLabel>
                                    <Select
                                        labelId="role-select-label"
                                        id="role-select"
                                        value={ v.appUserRole.replace('[','').replace(']','') }
                                        label="Rôle"
                                        onChange={(e) => handleRoleChange(e,v.email)}
                                    >
                                        <MenuItem value="ADMIN">ADMIN</MenuItem>
                                        <MenuItem value="AQUA2000">AQUA2000</MenuItem>
                                        <MenuItem value="TALENT">TALENT</MenuItem>
                                        <MenuItem value="TALENT_EXTERNE">TALENT_EXTERNE</MenuItem>
                                        <MenuItem value="USER">USER</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl>
                                    <InputLabel id="outbound-select-label">Outbounds</InputLabel>
                                    <Select
                                    fullWidth
                                    labelId="outbound-select-label"
                                    id="outbound-select"
                                    value=""
                                    label="Outbound"
                                    onChange={(e) => handleOutboundChange(e,v.email,v.outboundNumbers)}
                                    >
                                    {
                                        outboundNumbers.map(numb => (
                                            <MenuItem key={numb.outboundNumber} value={numb.outboundNumber}>
                                                <Checkbox
                                                    checked={
                                                        v.outboundNumbers.findIndex(item => item.outboundNumber === numb.outboundNumber) >= 0
                                                    }
                                                />
                                                <ListItemText primary={numb.outboundNumber} />
                                            </MenuItem>
                                        ))
                                    }
                                    </Select>
                                </FormControl>
                            </ListItem>
                        )) }
                        </List>
                    ) : "" }
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                        Numéros Outbound
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField label='Numéro de tél. outbound' name='phoneNumber' onChange={handleInput} placeholder='Entrez un numéro' fullWidth required/>
                        <Button type='submit' color='primary' variant="contained" style={btnstyle} fullWidth>Ajouter</Button>
                    </form>
                    <Typography sx={{ mt: 4, mb: 2 }} variant="h7" component="div">
                        Présents dans la liste
                    </Typography>
                    <List>
                        { outboundNumbers.map((item) => (
                            <ListItem>
                                <ListItemText
                                    primary={ item.outboundNumber }
                                />
                            </ListItem>
                        )) }
                    </List>
                </Grid>
            </Grid>
        </div>
    );
};

export default Admin;