import React, { useReducer } from "react";
import { Grid,Paper, Avatar, TextField, Button, Typography,Link,Dialog,Alert,AlertTitle } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from 'react-router-dom';
import {ReactComponent as FoundyLogo} from '../logo.svg'

const LoginForm=()=>{
    const navigate = useNavigate();
    const [formInput, setFormInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
          username: "",
          password: ""
        }
    );
    const handleSubmit = evt => {
        evt.preventDefault();
    
        let data = { formInput };

        var details = { "username": data.formInput.username, "password": data.formInput.password };
        var formBody = [];
        for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
    
        fetch("/perform_login", {
          method: "POST",
          body: formBody,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
          }
          /*body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json"
          }*/
        })
          .then( async (response) => {
            let data = await response.json();

            if(data.status === "ok") {
              localStorage.setItem('roles',data.roles);
              localStorage.setItem('details',data.details);
              localStorage.setItem('email',data.email);
              //navigate(data.target, { state: data.roles });
              navigate(data.target);
            } else {
              handleDialog();
            }
          });
    };
    const handleInput = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        setFormInput({ [name]: newValue });
    };
    const paperStyle={padding :20,width:280, margin:"20px auto"}
    const avatarStyle={backgroundColor:'#1bbd7e'}
    const btnstyle={margin:'8px 0'}

    const [open, setOpen] = React.useState(false);

    const handleDialog = () => {
      setOpen(!open);
    };

    return(
        <Grid>
            <Paper elevation={10} style={paperStyle}>
                <Grid align='center'>
                     <Avatar style={avatarStyle}><LockOutlinedIcon/></Avatar>
                    <FoundyLogo style={{width: "200px"}} />
                </Grid>
                <Dialog open={open} onClose={handleDialog}>
                    <Alert severity="error">
                    <AlertTitle>Erreur</AlertTitle>
                        Identifiants incorrects ou compte désactivé. Veuillez réessayer
                    </Alert>
                </Dialog>
                <form onSubmit={handleSubmit}>
                    <TextField label='Email' name='username' onChange={handleInput} placeholder='Entrez email' fullWidth required/>
                    <TextField label='Mot de passe' name='password' onChange={handleInput} placeholder='Entrez mot de passe' type='password' fullWidth required/>
                    <Button type='submit' color='primary' variant="contained" style={btnstyle} fullWidth>S'authentifier</Button>
                </form>
                <Typography > Avez-vous un compte ? 
                     <Link href="/register" >
                        Inscription
                </Link>
                </Typography>
            </Paper>
        </Grid>
    )
}

export default LoginForm
