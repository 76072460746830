import React from "react";
import { AppBar, Toolbar, CssBaseline, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./navbar.css";
import { ReactComponent as FoundyLogo } from "../logo.svg";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, Modal, Badge, IconButton } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { grey } from "@mui/material/colors";
import { Chip, Avatar } from "@mui/material";
import { deepOrange } from "@mui/material/colors";

export default function Navbar({ option }) {
  const useStyles = makeStyles((theme) => ({
    navlinks: {
      //marginLeft: theme.spacing(10),
      display: "flex",
    },
    logo: {
      flexGrow: "1",
      cursor: "pointer",
    },
    link: {
      textDecoration: "none",
      color: "white",
      fontSize: "20px",
      //marginLeft: theme.spacing(20),
      //"&:hover": {
      //  color: "yellow",
      //  borderBottom: "1px solid white",
      //},
    },
  }));

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "600px",
    maxHeight: "60%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
  };

  const classes = useStyles();
  /*            <Link to="/logout" className={classes.link}>
              Déconnexion
            </Link>*/

  const roles = localStorage.getItem("roles");
  const details = localStorage.getItem("details");
  const failedPartners = useSelector((state) => state.partners.failedPartners);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function getRandomColor(name) {
    // get first alphabet in upper case
    const firstAlphabet = name.charAt(0).toLowerCase();

    // get the ASCII code of the character
    const asciiCode = firstAlphabet.charCodeAt(0);

    // number that contains 3 times ASCII value of character -- unique for every alphabet
    const colorNum =
      asciiCode.toString() + asciiCode.toString() + asciiCode.toString();

    var num = Math.round(0xffffff * parseInt(colorNum));
    var r = (num >> 16) & 255;
    var g = (num >> 8) & 255;
    var b = num & 255;

    return "rgb(" + r + ", " + g + ", " + b + ", 1)";
  }

  return (
    <AppBar position="static" style={{ background: "#3a5268" }}>
      <CssBaseline />
      <Toolbar>
        <Box display="flex" flexGrow={1}>
          <FoundyLogo style={{ width: "47px" }} />
        </Box>
        <div className={classes.navlinks}>
          {option && option === "testodoo" && (
            <Chip
              label={"ODOO CRM AQUA2000"}
              sx={{ background: "greenyellow", color: "#000", fontWeight: 800 }}
            />
          )}
          <Chip
            avatar={
              <Avatar sx={{ bgcolor: getRandomColor(details) }}>
                {Array.from(details)[0]}
              </Avatar>
            }
            label={details}
            sx={{ background: "#10293f", color: "#fff" }}
            style={{ marginRight: 10 }}
          />
          <Link to="/" className={classes.link} style={{ marginRight: 10 }}>
            Accueil
          </Link>
          {roles == "[ADMIN]" /* roles == user , temporary */ ? (
            <Link
              to="/admin"
              className={classes.link}
              style={{ marginRight: 10 }}
            >
              Admin
            </Link>
          ) : (
            ""
          )}
          <a
            className={classes.link}
            style={{ color: "white", textDecoration: "none" }}
            href="/logout"
          >
            Déconnexion
          </a>
        </div>
        {failedPartners && failedPartners.length > 0 ? (
          <div>
            <IconButton>
              <Badge
                badgeContent={failedPartners.length}
                color="error"
                onClick={handleOpen}
              >
                <ErrorIcon color="error" />{" "}
              </Badge>
            </IconButton>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={modalStyle}>
                {failedPartners.map((failedPartner) => (
                  <Typography>
                    <b>{failedPartner.name}</b> (
                    <i>
                      Origine :{" "}
                      {failedPartner.origin == "odoo"
                        ? "TALENTFRANCE"
                        : "AQUA2000"}
                    </i>
                    ) <br />
                    {failedPartner.siret
                      ? failedPartner.siret
                      : "Pas de SIRET renseigné"}
                    , <br />
                    {failedPartner.address
                      ? failedPartner.address
                      : "Pas d'adresse renseignée"}
                    <br />
                    <br />
                  </Typography>
                ))}
              </Box>
            </Modal>
          </div>
        ) : (
          ""
        )}
      </Toolbar>
    </AppBar>
  );
}
