import React from "react";
import "./App.css";
import Login from "./pages/login";
import Register from "./pages/register";
import MainApp from "./pages/mainapp";
import Admin from "./pages/admin";
import Voip from "./pages/voip";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TalentHeatmap from "./pages/talentheatmap";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          exact
          path="/:defaultLongitude/:defaultLatitude"
          element={<MainApp />}
        />
        <Route exact path="/:option" element={<MainApp />} />
        <Route exact path="/" element={<MainApp />} />
        <Route path="/voip" element={<Voip />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/register" element={<Register />} />
        <Route path="/talentheatmap" element={<TalentHeatmap />} />
        <Route
          path="/logout"
          component={() => {
            // TODO a tester
            console.log("LOGOUT");
            window.location.replace("/logout");
            return null;
          }}
        />
      </Routes>
    </Router>
  );
}

export default App;
